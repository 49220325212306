/* Firefox */
.scroll-theme {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) #ffffff;
}

/* Chrome/Edge/Safari */
.scroll-theme::-webkit-scrollbar {
  -webkit-appearance: none;
}
.scroll-theme::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}
.scroll-theme::-webkit-scrollbar-thumb {
  border: 2px solid #ffffff;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.25);
}
.scroll-theme::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
