.announcement-banner {
  background-color: #ffffff;
  color: currentColor;
  font-size: 18px;
  font-weight: bold;
  padding: 1rem 15px;
}

.announcement-banner-text {
  max-width: 1248px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  white-space: pre-line;
  font-size: 16px;
}


@media screen and (max-width: 480px) {
  .announcement-banner-text {
    text-align: left;
  }
}
