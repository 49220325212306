.rc-time-picker {
  display: inline-flex;
  width: 148px;

  &-input,
  &-panel-input {
    border: 0;
    font-size: 1rem;
    color: currentColor;
    font-weight: 200;
  }

  &-panel {
    width: 163px;

    &-inner {
      border: 0;
      border-radius: 0;
      box-shadow: none;
    }

    &-select {
      border: none;
      font-size: 1rem;
      font-weight: 200;
      width: 50%;
    }

    &-input-wrap {
      padding-bottom: 11px;
    }
  }
}
.rc-time-picker-panel-inner {
  border-radius: 3px;
}

.rc-time-picker-panel-input-wrap {
  padding-bottom: 9px;
}

.rc-time-picker-panel-combobox {
  display: flex;
  border: 1px solid rgb(226, 232, 240);
  box-shadow: 0px 0px 8px -4px rgba(0, 0, 0, 0.25);
}

.rc-time-picker-panel-combobox + div {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.rc-time-picker-panel-select {
  max-height: 180px;
}

li.rc-time-picker-panel-select-option-disabled {
  // FIX: if hiding options when disabled, the 12 still shows, so hide all disabled options here.
  // display: none;
}

.rc-time-picker-input.focus-visible {
  outline: 0;
}
